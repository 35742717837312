import { ApolloClient, InMemoryCache } from '@apollo/client';

import { hygraphAPI } from 'utils/env';
const cache = new InMemoryCache({
  typePolicies: {
    PostConnection: {
      merge: true,
    },
  },
});

const client = new ApolloClient({
  uri: hygraphAPI,
  cache,
});

export default client;
