const title = 'Noxx - Payroll and Compliance tool for pseudonymous talent';
const description = 'Noxx landing page';
export type OpenGraphImage = {
  alt: string;
  height: number;
  type: string;
  url: string;
  width: number;
};
const SEO = {
  title,
  description,
  canonical: 'https://www.noxx.xyz',
  openGraph: {
    type: 'website',
    locale: 'en_IE',
    url: 'https://www.noxx.xyz/',
    title,
    description,
    images: [
      {
        url: 'https://www.noxx.xyz/ogp.jpg',
        alt: title,
        width: 1200,
        height: 600,
        type: 'image/jpg',
      },
    ],
    siteName: 'Noxx',
  },
  twitter: {
    site: '@no_doxx',
    cardType: 'summary_large_image',
    handle: '@no_doxx',
  },
};

export default SEO;
