import '../styles/globals.css';

import { useEffect } from 'react';

import { ApolloProvider } from '@apollo/client';
import { Analytics } from '@vercel/analytics/react';
import { DefaultSeo } from 'next-seo';
import { ThemeProvider } from 'next-themes';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import ReactGA from 'react-ga4';
import { useLocalStorage } from 'usehooks-ts';

import { clarityID } from 'utils/env';

import SEO from '../../next-seo-config';
import apolloClient from '../utils/apollo';

const CookieBanner = dynamic(() => import('components/ui/CookieBanner'), {
  ssr: false,
});

interface Cookies {
  consent: boolean;
  init: boolean;
}

function App({ Component, pageProps }: AppProps) {
  const router = useRouter();

  const [cookieConsent, setCookieConsent] = useLocalStorage<Cookies>(
    'cookieConsentLP',
    {
      consent: true,
      init: false,
    },
  );

  useEffect(() => {
    const { NEXT_PUBLIC_GOOGLE_ANALYTICS } = process.env;

    if (cookieConsent.consent && NEXT_PUBLIC_GOOGLE_ANALYTICS) {
      ReactGA.initialize(NEXT_PUBLIC_GOOGLE_ANALYTICS);

      const handleRouteChange = (url: string) => {
        ReactGA.send({ hitType: 'pageView', url });
      };

      //When the component is mounted, subscribe to router changes
      //and log those page views
      router.events.on('routeChangeComplete', handleRouteChange);

      // If the component is unmounted, unsubscribe
      // from the event with the `off` method
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
      };
    }
  }, [cookieConsent.consent, router.events]);

  const handleDecline = () => {
    setCookieConsent({
      consent: false,
      init: true,
    });
  };

  const handleConfirm = () => {
    setCookieConsent({
      consent: true,
      init: true,
    });
  };

  return (
    <>
      <DefaultSeo {...SEO} />
      {!cookieConsent.init && (
        <CookieBanner
          description={
            <>
              By clicking “Accept cookies” or continuing to browse this website,
              you agree Noxx can store cookies on your device. You can learn
              more about how we use cookies and set cookie preferences in
              Settings.
              <a
                href="https://knotinc.notion.site/Privacy-Policy-d1f467096d0e4145b63ebff6b90312f4"
                target="_blank"
                className="underline text-blue-500 ml-1 mr-1"
                rel="noreferrer"
              >
                Privacy Policy.
              </a>
            </>
          }
          onDecline={handleDecline}
          onAccept={handleConfirm}
        />
      )}
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="msapplication-config" content="/browserconfig.xml" />
        <meta name="msapplication-TileColor" content="#1C1C1C" />
        <meta name="theme-color" content="#1C1C1C" />
        {cookieConsent.consent && (
          <script
            dangerouslySetInnerHTML={{
              __html: `(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "${clarityID}");`,
            }}
          />
        )}
      </Head>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider attribute="class">
          <Component {...pageProps} />
        </ThemeProvider>
      </ApolloProvider>
      <Analytics />
    </>
  );
}

export default App;
